import { colorIsLight } from 'helpers/color-helper/color-helper';
import { useAppStore } from 'store/appStore';
import { getTheme } from 'components/app/App.helper';

export function PoweredByCaspeco() {
  const app = useAppStore();

  const isBgColorLight = () => {
    const theme = getTheme(app.appData);
    if (!theme) {
      return false;
    }

    const bgColor = theme.v2.colors?.background;
    if (!bgColor) {
      return false;
    }
    return colorIsLight(bgColor);
  };

  return <>{isBgColorLight() ? <BlackLogo /> : <WhiteLogo />}</>;
}

const WhiteLogo = () => {
  return (
    <svg
      width="116"
      height="37"
      viewBox="0 0 116 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.368 13V3.54544H21.5626C22.3043 3.54544 22.9106 3.67932 23.3815 3.94707C23.8555 4.21175 24.2063 4.5703 24.4341 5.02271C24.6618 5.47513 24.7757 5.97986 24.7757 6.53692C24.7757 7.09397 24.6618 7.60025 24.4341 8.05574C24.2094 8.51123 23.8616 8.87439 23.3908 9.14523C22.9199 9.41298 22.3167 9.54686 21.5811 9.54686H19.2913V8.53124H21.5442C22.052 8.53124 22.4598 8.44352 22.7675 8.2681C23.0753 8.09267 23.2984 7.85569 23.4369 7.55716C23.5785 7.25555 23.6493 6.91547 23.6493 6.53692C23.6493 6.15837 23.5785 5.81983 23.4369 5.52129C23.2984 5.22276 23.0738 4.98886 22.7629 4.81959C22.4521 4.64724 22.0397 4.56107 21.5257 4.56107H19.5129V13H18.368Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M30.2768 13.1477C29.6366 13.1477 29.0749 12.9954 28.5918 12.6907C28.1116 12.386 27.7362 11.9597 27.4653 11.4119C27.1976 10.8641 27.0637 10.2239 27.0637 9.49146C27.0637 8.75283 27.1976 8.10806 27.4653 7.55716C27.7362 7.00626 28.1116 6.57847 28.5918 6.27378C29.0749 5.96909 29.6366 5.81675 30.2768 5.81675C30.9169 5.81675 31.4771 5.96909 31.9572 6.27378C32.4404 6.57847 32.8158 7.00626 33.0836 7.55716C33.3544 8.10806 33.4898 8.75283 33.4898 9.49146C33.4898 10.2239 33.3544 10.8641 33.0836 11.4119C32.8158 11.9597 32.4404 12.386 31.9572 12.6907C31.4771 12.9954 30.9169 13.1477 30.2768 13.1477ZM30.2768 12.169C30.763 12.169 31.1631 12.0444 31.4771 11.7951C31.791 11.5458 32.0233 11.218 32.1741 10.8118C32.3249 10.4055 32.4003 9.96542 32.4003 9.49146C32.4003 9.01751 32.3249 8.57586 32.1741 8.16653C32.0233 7.75721 31.791 7.42636 31.4771 7.17399C31.1631 6.92162 30.763 6.79544 30.2768 6.79544C29.7905 6.79544 29.3904 6.92162 29.0765 7.17399C28.7626 7.42636 28.5302 7.75721 28.3794 8.16653C28.2286 8.57586 28.1532 9.01751 28.1532 9.49146C28.1532 9.96542 28.2286 10.4055 28.3794 10.8118C28.5302 11.218 28.7626 11.5458 29.0765 11.7951C29.3904 12.0444 29.7905 12.169 30.2768 12.169Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M37.5182 13L35.3577 5.90908H36.5026L38.0353 11.3381H38.1092L39.6234 5.90908H40.7867L42.2824 11.3196H42.3563L43.889 5.90908H45.0339L42.8734 13H41.8023L40.2512 7.55254H40.1404L38.5893 13H37.5182Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M50.206 13.1477C49.5228 13.1477 48.9334 12.9969 48.4379 12.6953C47.9455 12.3906 47.5654 11.9659 47.2976 11.4212C47.033 10.8733 46.9006 10.2363 46.9006 9.50993C46.9006 8.7836 47.033 8.14345 47.2976 7.58947C47.5654 7.03242 47.9378 6.59847 48.4148 6.28763C48.8949 5.97371 49.4551 5.81675 50.0952 5.81675C50.4645 5.81675 50.8292 5.8783 51.1893 6.00141C51.5494 6.12451 51.8772 6.32456 52.1726 6.60155C52.4681 6.87546 52.7035 7.23862 52.879 7.69104C53.0544 8.14345 53.1421 8.70051 53.1421 9.3622V9.82385H47.6762V8.88209H52.0341C52.0341 8.48199 51.9541 8.12499 51.7941 7.81107C51.6371 7.49715 51.4124 7.24939 51.1201 7.06781C50.8308 6.88623 50.4892 6.79544 50.0952 6.79544C49.6613 6.79544 49.2858 6.90316 48.9688 7.11859C48.6549 7.33095 48.4133 7.60794 48.244 7.94956C48.0747 8.29118 47.9901 8.65742 47.9901 9.04828V9.67612C47.9901 10.2116 48.0824 10.6656 48.2671 11.038C48.4548 11.4073 48.7149 11.6889 49.0473 11.8828C49.3797 12.0736 49.7659 12.169 50.206 12.169C50.4922 12.169 50.7508 12.129 50.9816 12.049C51.2155 11.9659 51.4171 11.8428 51.5863 11.6797C51.7556 11.5135 51.8864 11.3073 51.9787 11.0611L53.0313 11.3565C52.9205 11.7135 52.7343 12.0274 52.4727 12.2983C52.2111 12.566 51.8879 12.7753 51.5032 12.9261C51.1185 13.0738 50.6861 13.1477 50.206 13.1477Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M55.7994 13V5.90908H56.852V6.9801H56.9258C57.0551 6.62925 57.289 6.34456 57.6275 6.12605C57.9661 5.90754 58.3477 5.79828 58.7724 5.79828C58.8524 5.79828 58.9525 5.79982 59.0725 5.8029C59.1925 5.80598 59.2833 5.81059 59.3449 5.81675V6.9247C59.3079 6.91547 59.2233 6.90162 59.091 6.88315C58.9617 6.86161 58.8247 6.85084 58.6801 6.85084C58.3354 6.85084 58.0276 6.92316 57.7568 7.06781C57.489 7.20939 57.2767 7.40635 57.1197 7.65872C56.9658 7.90801 56.8889 8.1927 56.8889 8.51277V13H55.7994Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M64.3935 13.1477C63.7103 13.1477 63.1209 12.9969 62.6254 12.6953C62.133 12.3906 61.7529 11.9659 61.4851 11.4212C61.2205 10.8733 61.0881 10.2363 61.0881 9.50993C61.0881 8.7836 61.2205 8.14345 61.4851 7.58947C61.7529 7.03242 62.1253 6.59847 62.6023 6.28763C63.0824 5.97371 63.6426 5.81675 64.2827 5.81675C64.652 5.81675 65.0167 5.8783 65.3768 6.00141C65.7369 6.12451 66.0647 6.32456 66.3601 6.60155C66.6556 6.87546 66.891 7.23862 67.0665 7.69104C67.2419 8.14345 67.3296 8.70051 67.3296 9.3622V9.82385H61.8637V8.88209H66.2216C66.2216 8.48199 66.1416 8.12499 65.9816 7.81107C65.8246 7.49715 65.5999 7.24939 65.3076 7.06781C65.0183 6.88623 64.6767 6.79544 64.2827 6.79544C63.8488 6.79544 63.4733 6.90316 63.1563 7.11859C62.8424 7.33095 62.6008 7.60794 62.4315 7.94956C62.2622 8.29118 62.1776 8.65742 62.1776 9.04828V9.67612C62.1776 10.2116 62.2699 10.6656 62.4546 11.038C62.6423 11.4073 62.9024 11.6889 63.2348 11.8828C63.5672 12.0736 63.9534 12.169 64.3935 12.169C64.6797 12.169 64.9383 12.129 65.1691 12.049C65.403 11.9659 65.6046 11.8428 65.7738 11.6797C65.9431 11.5135 66.0739 11.3073 66.1662 11.0611L67.2188 11.3565C67.108 11.7135 66.9218 12.0274 66.6602 12.2983C66.3986 12.566 66.0754 12.7753 65.6907 12.9261C65.306 13.0738 64.8736 13.1477 64.3935 13.1477Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M72.6645 13.1477C72.0736 13.1477 71.5519 12.9984 71.0995 12.6999C70.6471 12.3983 70.2931 11.9736 70.0377 11.4258C69.7822 10.8749 69.6545 10.2239 69.6545 9.473C69.6545 8.72821 69.7822 8.0819 70.0377 7.53408C70.2931 6.98626 70.6486 6.56308 71.1041 6.26455C71.5596 5.96601 72.0859 5.81675 72.6829 5.81675C73.1446 5.81675 73.5093 5.89369 73.777 6.04757C74.0479 6.19838 74.2541 6.37073 74.3956 6.56462C74.5403 6.75543 74.6526 6.91239 74.7326 7.0355H74.825V3.54544H75.9145V13H74.8619V11.9105H74.7326C74.6526 12.0398 74.5388 12.2029 74.391 12.3998C74.2433 12.5937 74.0325 12.7676 73.7586 12.9215C73.4847 13.0723 73.12 13.1477 72.6645 13.1477ZM72.8122 12.169C73.2492 12.169 73.6185 12.0551 73.9201 11.8274C74.2218 11.5966 74.451 11.278 74.608 10.8718C74.765 10.4625 74.8434 9.99004 74.8434 9.45453C74.8434 8.92518 74.7665 8.46199 74.6126 8.06497C74.4587 7.66488 74.231 7.35403 73.9294 7.13244C73.6278 6.90777 73.2554 6.79544 72.8122 6.79544C72.3505 6.79544 71.9658 6.91393 71.6581 7.15091C71.3534 7.38481 71.1241 7.70335 70.9702 8.10652C70.8194 8.50662 70.744 8.95595 70.744 9.45453C70.744 9.95927 70.8209 10.4178 70.9748 10.8302C71.1318 11.2396 71.3626 11.5658 71.6673 11.8089C71.9751 12.049 72.3567 12.169 72.8122 12.169Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M83.8651 13V3.54544H84.9546V7.0355H85.0469C85.1269 6.91239 85.2377 6.75543 85.3793 6.56462C85.524 6.37073 85.7302 6.19838 85.9979 6.04757C86.2687 5.89369 86.635 5.81675 87.0966 5.81675C87.6937 5.81675 88.22 5.96601 88.6755 6.26455C89.131 6.56308 89.4864 6.98626 89.7419 7.53408C89.9973 8.0819 90.125 8.72821 90.125 9.473C90.125 10.2239 89.9973 10.8749 89.7419 11.4258C89.4864 11.9736 89.1325 12.3983 88.6801 12.6999C88.2277 12.9984 87.706 13.1477 87.1151 13.1477C86.6596 13.1477 86.2949 13.0723 86.021 12.9215C85.7471 12.7676 85.5363 12.5937 85.3885 12.3998C85.2408 12.2029 85.1269 12.0398 85.0469 11.9105H84.9177V13H83.8651ZM84.9361 9.45453C84.9361 9.99004 85.0146 10.4625 85.1716 10.8718C85.3285 11.278 85.5578 11.5966 85.8594 11.8274C86.161 12.0551 86.5303 12.169 86.9674 12.169C87.4229 12.169 87.803 12.049 88.1076 11.8089C88.4154 11.5658 88.6462 11.2396 88.8001 10.8302C88.9571 10.4178 89.0355 9.95927 89.0355 9.45453C89.0355 8.95595 88.9586 8.50662 88.8047 8.10652C88.6539 7.70335 88.4246 7.38481 88.1169 7.15091C87.8122 6.91393 87.429 6.79544 86.9674 6.79544C86.5242 6.79544 86.1518 6.90777 85.8502 7.13244C85.5486 7.35403 85.3208 7.66488 85.1669 8.06497C85.0131 8.46199 84.9361 8.92518 84.9361 9.45453Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M93.2394 15.6591C93.0547 15.6591 92.8901 15.6437 92.7454 15.6129C92.6008 15.5852 92.5008 15.5575 92.4454 15.5298L92.7223 14.5696C92.987 14.6373 93.2209 14.6619 93.424 14.6435C93.6272 14.625 93.8072 14.5342 93.9642 14.3711C94.1242 14.211 94.2704 13.951 94.4027 13.5909L94.6059 13.0369L91.9837 5.90908H93.1655L95.1229 11.5596H95.1968L97.1542 5.90908H98.336L95.326 14.0341C95.1906 14.4003 95.0229 14.7035 94.8228 14.9435C94.6228 15.1867 94.3904 15.3667 94.1258 15.4837C93.8642 15.6006 93.5687 15.6591 93.2394 15.6591Z"
        fill="white"
        fillOpacity="0.48"
      />
      <path
        d="M65.9272 19.3338H79.308V23.4285H70.5464V26.1642H75.1298V29.1205H70.5464V32.2198H79.5524V36.541H65.9272V19.3338Z"
        fill="white"
      />
      <path
        d="M34.4093 31.0576H39.547C39.7199 32.4225 40.7749 33.0841 42.4378 33.0841C43.4212 33.0841 44.6312 32.7086 44.6312 31.6655C44.6312 30.6225 43.4808 30.3602 41.359 29.8477C38.4682 29.1265 34.9874 28.143 34.9874 24.4834C34.9994 20.5437 38.2119 19.0477 42.0861 19.0477C45.6384 19.0477 48.9106 20.5437 48.9821 24.2987H44.0113C43.8623 23.351 43.2007 22.7669 41.8179 22.7669C40.7212 22.7669 39.9464 23.2795 39.9464 23.9589C39.9464 24.9602 41.4186 25.1152 43.9934 25.741C46.8841 26.4444 49.757 27.5589 49.757 31.2185C49.757 34.8781 46.4133 36.9583 42.3484 36.9583C37.8722 36.9523 34.4868 35.0212 34.4093 31.0576Z"
        fill="white"
      />
      <path
        d="M28.5623 19.3338H23.4424L17.1066 36.5351H22.0954L22.9477 34.0139H27.2808L26.3391 31.0576H23.8954L25.8384 24.9602H25.898L29.6053 36.5351H34.9338L28.5623 19.3338Z"
        fill="white"
      />
      <path
        d="M62.959 20.8953C61.8444 19.8881 60.6643 19.3338 58.4709 19.3338H50.955V36.5351H55.5742L55.5921 23.4404H58.3159C59.6212 23.4404 60.3007 24.394 60.3007 25.5324C60.3007 26.6708 59.5557 27.6245 58.3159 27.6245H56.5457L57.6484 31.0695H58.9716C62.4285 31.0695 64.8901 28.6139 64.8901 25.3596C64.8901 23.5 64.1868 21.9563 62.959 20.8953Z"
        fill="white"
      />
      <path
        d="M106.242 19.0477C101.325 19.0477 97.3259 23.0649 97.3259 28C97.3259 32.9351 101.325 36.9523 106.242 36.9523C111.16 36.9523 115.159 32.9351 115.159 28C115.159 23.0649 111.16 19.0477 106.242 19.0477ZM110.546 28C110.546 30.3841 108.615 32.3212 106.236 32.3212C103.864 32.3212 101.933 30.3841 101.933 28C101.933 25.6159 103.864 23.6788 106.236 23.6788C108.615 23.6788 110.546 25.6159 110.546 28Z"
        fill="white"
      />
      <path
        d="M13.155 30.6583L13.1192 30.7059C12.2967 31.7311 11.0748 32.3212 9.76358 32.3212C7.3914 32.3212 5.46027 30.3841 5.46027 28C5.46027 25.6159 7.3914 23.6788 9.76358 23.6788C11.0748 23.6788 12.2967 24.2689 13.1192 25.294L13.155 25.3417H18.108L18.0543 25.1748C17.4642 23.3987 16.4629 21.8788 14.9848 20.7761C13.4709 19.6437 11.6649 19.0477 9.75762 19.0477C4.8404 19.0477 0.841061 23.0649 0.841061 28C0.841061 32.9351 4.8404 36.9523 9.75762 36.9523C11.6649 36.9523 13.4709 36.3563 14.9848 35.2238C16.4629 34.1212 17.4642 32.6013 18.0543 30.8251L18.108 30.6583H13.155Z"
        fill="white"
      />
      <path
        d="M91.8901 30.6583L91.8544 30.7059C91.0318 31.7311 89.81 32.3212 88.4987 32.3212C86.1265 32.3212 84.1954 30.3841 84.1954 28C84.1954 25.6159 86.1265 23.6788 88.4987 23.6788C89.81 23.6788 91.0318 24.2689 91.8544 25.294L91.8901 25.3417H96.8431L96.7895 25.1748C96.1994 23.3987 95.1981 21.8788 93.7199 20.7761C92.206 19.6437 90.4001 19.0477 88.4928 19.0477C83.5755 19.0477 79.5762 23.0649 79.5762 28C79.5762 32.9351 83.5755 36.9523 88.4928 36.9523C90.4001 36.9523 92.206 36.3563 93.7199 35.2238C95.1981 34.1212 96.1994 32.6013 96.7895 30.8251L96.8431 30.6583H91.8901Z"
        fill="white"
      />
    </svg>
  );
};

const BlackLogo = () => {
  return (
    <svg
      width="116"
      height="37"
      viewBox="0 0 116 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.368 13V3.54544H21.5626C22.3043 3.54544 22.9106 3.67932 23.3815 3.94707C23.8555 4.21175 24.2063 4.5703 24.4341 5.02271C24.6618 5.47513 24.7757 5.97986 24.7757 6.53692C24.7757 7.09397 24.6618 7.60025 24.4341 8.05574C24.2094 8.51123 23.8616 8.87439 23.3908 9.14523C22.9199 9.41298 22.3167 9.54686 21.5811 9.54686H19.2913V8.53124H21.5442C22.052 8.53124 22.4598 8.44352 22.7675 8.2681C23.0753 8.09267 23.2984 7.85569 23.4369 7.55716C23.5785 7.25555 23.6493 6.91547 23.6493 6.53692C23.6493 6.15837 23.5785 5.81983 23.4369 5.52129C23.2984 5.22276 23.0738 4.98886 22.7629 4.81959C22.4521 4.64724 22.0397 4.56107 21.5257 4.56107H19.5129V13H18.368Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M30.2768 13.1477C29.6366 13.1477 29.0749 12.9954 28.5917 12.6907C28.1116 12.386 27.7362 11.9597 27.4653 11.4119C27.1976 10.8641 27.0637 10.2239 27.0637 9.49146C27.0637 8.75283 27.1976 8.10806 27.4653 7.55716C27.7362 7.00626 28.1116 6.57847 28.5917 6.27378C29.0749 5.96909 29.6366 5.81675 30.2768 5.81675C30.9169 5.81675 31.477 5.96909 31.9572 6.27378C32.4404 6.57847 32.8158 7.00626 33.0836 7.55716C33.3544 8.10806 33.4898 8.75283 33.4898 9.49146C33.4898 10.2239 33.3544 10.8641 33.0836 11.4119C32.8158 11.9597 32.4404 12.386 31.9572 12.6907C31.477 12.9954 30.9169 13.1477 30.2768 13.1477ZM30.2768 12.169C30.763 12.169 31.1631 12.0444 31.477 11.7951C31.791 11.5458 32.0233 11.218 32.1741 10.8118C32.3249 10.4055 32.4003 9.96542 32.4003 9.49146C32.4003 9.01751 32.3249 8.57586 32.1741 8.16653C32.0233 7.75721 31.791 7.42636 31.477 7.17399C31.1631 6.92162 30.763 6.79544 30.2768 6.79544C29.7905 6.79544 29.3904 6.92162 29.0765 7.17399C28.7626 7.42636 28.5302 7.75721 28.3794 8.16653C28.2286 8.57586 28.1532 9.01751 28.1532 9.49146C28.1532 9.96542 28.2286 10.4055 28.3794 10.8118C28.5302 11.218 28.7626 11.5458 29.0765 11.7951C29.3904 12.0444 29.7905 12.169 30.2768 12.169Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M37.5182 13L35.3577 5.90908H36.5026L38.0353 11.3381H38.1091L39.6234 5.90908H40.7867L42.2824 11.3196H42.3563L43.889 5.90908H45.0339L42.8734 13H41.8023L40.2512 7.55254H40.1404L38.5893 13H37.5182Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M50.206 13.1477C49.5228 13.1477 48.9334 12.9969 48.4379 12.6953C47.9455 12.3906 47.5654 11.9659 47.2976 11.4212C47.0329 10.8733 46.9006 10.2363 46.9006 9.50993C46.9006 8.7836 47.0329 8.14345 47.2976 7.58947C47.5654 7.03242 47.9378 6.59847 48.4148 6.28763C48.8949 5.97371 49.4551 5.81675 50.0952 5.81675C50.4645 5.81675 50.8292 5.8783 51.1893 6.00141C51.5494 6.12451 51.8772 6.32456 52.1726 6.60155C52.4681 6.87546 52.7035 7.23862 52.8789 7.69104C53.0544 8.14345 53.1421 8.70051 53.1421 9.3622V9.82385H47.6762V8.88209H52.0341C52.0341 8.48199 51.9541 8.12499 51.7941 7.81107C51.6371 7.49715 51.4124 7.24939 51.1201 7.06781C50.8308 6.88623 50.4892 6.79544 50.0952 6.79544C49.6613 6.79544 49.2858 6.90316 48.9688 7.11859C48.6549 7.33095 48.4133 7.60794 48.244 7.94956C48.0747 8.29118 47.9901 8.65742 47.9901 9.04828V9.67612C47.9901 10.2116 48.0824 10.6656 48.2671 11.038C48.4548 11.4073 48.7149 11.6889 49.0473 11.8828C49.3797 12.0736 49.7659 12.169 50.206 12.169C50.4922 12.169 50.7508 12.129 50.9816 12.049C51.2155 11.9659 51.4171 11.8428 51.5863 11.6797C51.7556 11.5135 51.8864 11.3073 51.9787 11.0611L53.0313 11.3565C52.9205 11.7135 52.7343 12.0274 52.4727 12.2983C52.2111 12.566 51.8879 12.7753 51.5032 12.9261C51.1185 13.0738 50.6861 13.1477 50.206 13.1477Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M55.7994 13V5.90908H56.852V6.9801H56.9258C57.0551 6.62925 57.289 6.34456 57.6275 6.12605C57.9661 5.90754 58.3477 5.79828 58.7724 5.79828C58.8524 5.79828 58.9525 5.79982 59.0725 5.8029C59.1925 5.80598 59.2833 5.81059 59.3449 5.81675V6.9247C59.3079 6.91547 59.2233 6.90162 59.091 6.88315C58.9617 6.86161 58.8247 6.85084 58.6801 6.85084C58.3354 6.85084 58.0276 6.92316 57.7568 7.06781C57.489 7.20939 57.2767 7.40635 57.1197 7.65872C56.9658 7.90801 56.8889 8.1927 56.8889 8.51277V13H55.7994Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M64.3935 13.1477C63.7103 13.1477 63.1209 12.9969 62.6254 12.6953C62.133 12.3906 61.7529 11.9659 61.4851 11.4212C61.2204 10.8733 61.0881 10.2363 61.0881 9.50993C61.0881 8.7836 61.2204 8.14345 61.4851 7.58947C61.7529 7.03242 62.1253 6.59847 62.6023 6.28763C63.0824 5.97371 63.6426 5.81675 64.2827 5.81675C64.652 5.81675 65.0167 5.8783 65.3768 6.00141C65.7369 6.12451 66.0647 6.32456 66.3601 6.60155C66.6556 6.87546 66.891 7.23862 67.0664 7.69104C67.2419 8.14345 67.3296 8.70051 67.3296 9.3622V9.82385H61.8637V8.88209H66.2216C66.2216 8.48199 66.1416 8.12499 65.9816 7.81107C65.8246 7.49715 65.5999 7.24939 65.3076 7.06781C65.0183 6.88623 64.6767 6.79544 64.2827 6.79544C63.8488 6.79544 63.4733 6.90316 63.1563 7.11859C62.8424 7.33095 62.6008 7.60794 62.4315 7.94956C62.2622 8.29118 62.1776 8.65742 62.1776 9.04828V9.67612C62.1776 10.2116 62.2699 10.6656 62.4546 11.038C62.6423 11.4073 62.9024 11.6889 63.2348 11.8828C63.5672 12.0736 63.9534 12.169 64.3935 12.169C64.6797 12.169 64.9383 12.129 65.1691 12.049C65.403 11.9659 65.6046 11.8428 65.7738 11.6797C65.9431 11.5135 66.0739 11.3073 66.1662 11.0611L67.2188 11.3565C67.108 11.7135 66.9218 12.0274 66.6602 12.2983C66.3986 12.566 66.0754 12.7753 65.6907 12.9261C65.306 13.0738 64.8736 13.1477 64.3935 13.1477Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M72.6645 13.1477C72.0736 13.1477 71.5519 12.9984 71.0995 12.6999C70.6471 12.3983 70.2931 11.9736 70.0377 11.4258C69.7822 10.8749 69.6545 10.2239 69.6545 9.473C69.6545 8.72821 69.7822 8.0819 70.0377 7.53408C70.2931 6.98626 70.6486 6.56308 71.1041 6.26455C71.5596 5.96601 72.0859 5.81675 72.6829 5.81675C73.1446 5.81675 73.5093 5.89369 73.777 6.04757C74.0479 6.19838 74.2541 6.37073 74.3956 6.56462C74.5403 6.75543 74.6526 6.91239 74.7326 7.0355H74.825V3.54544H75.9145V13H74.8619V11.9105H74.7326C74.6526 12.0398 74.5387 12.2029 74.391 12.3998C74.2433 12.5937 74.0325 12.7676 73.7586 12.9215C73.4847 13.0723 73.12 13.1477 72.6645 13.1477ZM72.8122 12.169C73.2492 12.169 73.6185 12.0551 73.9201 11.8274C74.2217 11.5966 74.451 11.278 74.608 10.8718C74.765 10.4625 74.8434 9.99004 74.8434 9.45453C74.8434 8.92518 74.7665 8.46199 74.6126 8.06497C74.4587 7.66488 74.231 7.35403 73.9294 7.13244C73.6278 6.90777 73.2554 6.79544 72.8122 6.79544C72.3505 6.79544 71.9658 6.91393 71.6581 7.15091C71.3534 7.38481 71.1241 7.70335 70.9702 8.10652C70.8194 8.50662 70.744 8.95595 70.744 9.45453C70.744 9.95927 70.8209 10.4178 70.9748 10.8302C71.1318 11.2396 71.3626 11.5658 71.6673 11.8089C71.9751 12.049 72.3567 12.169 72.8122 12.169Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M83.8651 13V3.54544H84.9546V7.0355H85.0469C85.1269 6.91239 85.2377 6.75543 85.3793 6.56462C85.5239 6.37073 85.7302 6.19838 85.9979 6.04757C86.2687 5.89369 86.635 5.81675 87.0966 5.81675C87.6937 5.81675 88.22 5.96601 88.6755 6.26455C89.131 6.56308 89.4864 6.98626 89.7419 7.53408C89.9973 8.0819 90.125 8.72821 90.125 9.473C90.125 10.2239 89.9973 10.8749 89.7419 11.4258C89.4864 11.9736 89.1325 12.3983 88.6801 12.6999C88.2277 12.9984 87.706 13.1477 87.1151 13.1477C86.6596 13.1477 86.2949 13.0723 86.021 12.9215C85.7471 12.7676 85.5363 12.5937 85.3885 12.3998C85.2408 12.2029 85.1269 12.0398 85.0469 11.9105H84.9176V13H83.8651ZM84.9361 9.45453C84.9361 9.99004 85.0146 10.4625 85.1716 10.8718C85.3285 11.278 85.5578 11.5966 85.8594 11.8274C86.161 12.0551 86.5303 12.169 86.9674 12.169C87.4229 12.169 87.8029 12.049 88.1076 11.8089C88.4154 11.5658 88.6462 11.2396 88.8001 10.8302C88.9571 10.4178 89.0355 9.95927 89.0355 9.45453C89.0355 8.95595 88.9586 8.50662 88.8047 8.10652C88.6539 7.70335 88.4246 7.38481 88.1169 7.15091C87.8122 6.91393 87.429 6.79544 86.9674 6.79544C86.5242 6.79544 86.1518 6.90777 85.8502 7.13244C85.5486 7.35403 85.3208 7.66488 85.1669 8.06497C85.0131 8.46199 84.9361 8.92518 84.9361 9.45453Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M93.2394 15.6591C93.0547 15.6591 92.8901 15.6437 92.7454 15.6129C92.6008 15.5852 92.5007 15.5575 92.4454 15.5298L92.7223 14.5696C92.987 14.6373 93.2209 14.6619 93.424 14.6435C93.6272 14.625 93.8072 14.5342 93.9642 14.3711C94.1242 14.211 94.2704 13.951 94.4027 13.5909L94.6059 13.0369L91.9837 5.90908H93.1655L95.1229 11.5596H95.1968L97.1542 5.90908H98.336L95.326 14.0341C95.1906 14.4003 95.0229 14.7035 94.8228 14.9435C94.6228 15.1867 94.3904 15.3667 94.1257 15.4837C93.8642 15.6006 93.5687 15.6591 93.2394 15.6591Z"
        fill="#151411"
        fillOpacity="0.48"
      />
      <path
        d="M65.9272 19.3338H79.308V23.4285H70.5464V26.1642H75.1298V29.1205H70.5464V32.2199H79.5524V36.541H65.9272V19.3338Z"
        fill="#151411"
      />
      <path
        d="M34.4093 31.0576H39.547C39.7199 32.4225 40.7749 33.0841 42.4378 33.0841C43.4212 33.0841 44.6312 32.7086 44.6312 31.6655C44.6312 30.6225 43.4808 30.3602 41.359 29.8477C38.4682 29.1265 34.9874 28.143 34.9874 24.4834C34.9994 20.5437 38.2119 19.0477 42.0861 19.0477C45.6384 19.0477 48.9106 20.5437 48.9822 24.2987H44.0113C43.8623 23.351 43.2007 22.7669 41.8179 22.7669C40.7212 22.7669 39.9464 23.2795 39.9464 23.9589C39.9464 24.9602 41.4186 25.1152 43.9934 25.741C46.8841 26.4444 49.757 27.5589 49.757 31.2185C49.757 34.8781 46.4133 36.9583 42.3484 36.9583C37.8722 36.9523 34.4868 35.0212 34.4093 31.0576Z"
        fill="#151411"
      />
      <path
        d="M28.5623 19.3338H23.4424L17.1066 36.5351H22.0954L22.9477 34.0139H27.2808L26.3391 31.0576H23.8954L25.8384 24.9602H25.898L29.6053 36.5351H34.9338L28.5623 19.3338Z"
        fill="#151411"
      />
      <path
        d="M62.959 20.8953C61.8444 19.8881 60.6643 19.3338 58.4709 19.3338H50.955V36.5351H55.5742L55.5921 23.4404H58.3159C59.6212 23.4404 60.3007 24.394 60.3007 25.5324C60.3007 26.6708 59.5557 27.6245 58.3159 27.6245H56.5457L57.6484 31.0695H58.9716C62.4285 31.0695 64.8901 28.6139 64.8901 25.3596C64.8901 23.5 64.1868 21.9563 62.959 20.8953Z"
        fill="#151411"
      />
      <path
        d="M106.242 19.0477C101.325 19.0477 97.3259 23.0649 97.3259 28C97.3259 32.9351 101.325 36.9523 106.242 36.9523C111.16 36.9523 115.159 32.9351 115.159 28C115.159 23.0649 111.16 19.0477 106.242 19.0477ZM110.546 28C110.546 30.3841 108.615 32.3212 106.236 32.3212C103.864 32.3212 101.933 30.3841 101.933 28C101.933 25.6159 103.864 23.6788 106.236 23.6788C108.615 23.6788 110.546 25.6159 110.546 28Z"
        fill="#151411"
      />
      <path
        d="M13.155 30.6583L13.1192 30.7059C12.2967 31.7311 11.0748 32.3212 9.76359 32.3212C7.3914 32.3212 5.46027 30.3841 5.46027 28C5.46027 25.6159 7.3914 23.6788 9.76359 23.6788C11.0748 23.6788 12.2967 24.2689 13.1192 25.294L13.155 25.3417H18.108L18.0543 25.1748C17.4643 23.3987 16.4629 21.8788 14.9848 20.7761C13.4709 19.6437 11.6649 19.0477 9.75763 19.0477C4.8404 19.0477 0.841064 23.0649 0.841064 28C0.841064 32.9351 4.8404 36.9523 9.75763 36.9523C11.6649 36.9523 13.4709 36.3563 14.9848 35.2238C16.4629 34.1212 17.4643 32.6013 18.0543 30.8251L18.108 30.6583H13.155Z"
        fill="#151411"
      />
      <path
        d="M91.8901 30.6583L91.8544 30.7059C91.0318 31.7311 89.81 32.3212 88.4987 32.3212C86.1265 32.3212 84.1954 30.3841 84.1954 28C84.1954 25.6159 86.1265 23.6788 88.4987 23.6788C89.81 23.6788 91.0318 24.2689 91.8544 25.294L91.8901 25.3417H96.8431L96.7895 25.1748C96.1994 23.3987 95.1981 21.8788 93.7199 20.7761C92.206 19.6437 90.4001 19.0477 88.4928 19.0477C83.5755 19.0477 79.5762 23.0649 79.5762 28C79.5762 32.9351 83.5755 36.9523 88.4928 36.9523C90.4001 36.9523 92.206 36.3563 93.7199 35.2238C95.1981 34.1212 96.1994 32.6013 96.7895 30.8251L96.8431 30.6583H91.8901Z"
        fill="#151411"
      />
    </svg>
  );
};
