import { createLocalOpeningHoursHelper } from 'helpers/opening-hours-helper/opening-hours-helper';
import { DateTime } from 'luxon';
import { ILocation } from './location';
import { IOpeningHours, IOpeningHoursDisplayData } from './openingHours';
import { DeliveryType } from './deliveryOption';
import { ImageMap, ImageMapValues } from './imageMap';

type IPlaceSupportedDeliveryMethodsResponse = {
  deliveryMethod: DeliveryType;
  menuIds: string[];
};

interface IGroupedOrdersResponse {
  enabled?: boolean;
  minutes?: number;
}

interface IPlaceAdress {
  city?: string;
  country?: string;
  streetAddress?: string;
}

class SupportedDeliveryTypeMenuMap {
  /**
   * DeliveryMethod and DeliveryType are synonyms and can be used interchangeably.
   * from backend this value is received as 'deliveryMethod' here,
   * but for readability and consistency in the platform we call it deliveryType in this context.
   */
  deliveryType: DeliveryType;
  menuIds: string[];
  constructor(values: IPlaceSupportedDeliveryMethodsResponse) {
    this.deliveryType = values?.deliveryMethod;
    this.menuIds = values?.menuIds ?? [];
  }
}

export class Place {
  id: string;
  name: string;
  iconUrl: string;
  location: ILocation;
  openingHours: IOpeningHours[];
  timeZoneName: string;
  description: string;
  imageMap: ImageMap;
  open: boolean;
  openingStatus: IOpeningHoursDisplayData;
  address: IPlaceAdress;
  phone: string;
  email: string;
  corporateId: string;
  menuIds: string[];
  menuIdsPerSupportedDeliveryType: SupportedDeliveryTypeMenuMap[];
  canTakeOrders: boolean;
  preOrderEnabled: boolean;
  eatHereRootResourceId: string | undefined;
  supportedDeliveryTypes: DeliveryType[];
  smsEnabled: boolean;

  constructor(values: IPlaceResponse) {
    const now = DateTime.now();
    const { isOpen, getOpeningStatus } = createLocalOpeningHoursHelper(
      values?.openingHours ?? [],
      values?.timeZoneName,
    );
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.iconUrl = values?.iconUrl ?? '';
    this.location = values?.location ?? <ILocation>{};
    this.openingHours = values?.openingHours ?? [];
    this.timeZoneName = values?.timeZoneName ?? 'Europe/Stockholm';
    this.description = values?.description ?? '';
    this.imageMap = new ImageMap(values?.imageMap);
    this.open = isOpen(now) ?? false;
    this.openingStatus = getOpeningStatus(now);
    this.address = values?.address ?? {
      city: '',
      country: '',
      streetAddress: '',
    };
    this.phone = values?.phone ?? '';
    this.email = values?.email ?? '';
    this.corporateId = values?.corporateId ?? '';
    this.menuIds = values?.menuIds ?? [];
    this.canTakeOrders = values?.canTakeOrders ?? false;
    this.preOrderEnabled = values?.preOrderEnabled ?? false;
    this.menuIdsPerSupportedDeliveryType =
      values?.supportedDeliveryMethods?.map(
        (deliveryMethod) => new SupportedDeliveryTypeMenuMap(deliveryMethod),
      ) ?? [];
    this.supportedDeliveryTypes = this.menuIdsPerSupportedDeliveryType
      .filter((menus) => menus.menuIds.length > 0)
      .map((menus) => menus.deliveryType);
    this.eatHereRootResourceId = values?.eatHereRootResourceId ?? '';
    this.smsEnabled = values?.smsEnabled ?? false;
  }

  /** Set place distance in relation to user location. */
  setLocationDistance(this: Place, distanceInKm: number) {
    this.location.distanceInKm = distanceInKm;
  }
}

export interface IPlaceResponse {
  id?: string;
  name?: string;
  iconUrl?: string;
  location?: ILocation;
  imageMap?: ImageMapValues;
  openingHours?: IOpeningHours[];
  timeZoneName?: string;
  description?: string;
  address?: IPlaceAdress;
  phone?: string;
  email?: string;
  corporateId?: string;
  menuIds?: string[];
  canTakeOrders?: boolean;
  supportedDeliveryMethods?: IPlaceSupportedDeliveryMethodsResponse[];
  preOrderEnabled?: boolean;
  eatHereRootResourceId?: string;
  smsEnabled?: boolean;
}

export interface IPlaceDetailResponse extends IPlaceResponse {
  groupedOrders?: IGroupedOrdersResponse;
}

export class PlaceDetails extends Place {
  groupedOrders: IGroupedOrdersResponse;
  constructor(values: IPlaceDetailResponse) {
    super(values);
    this.groupedOrders = values?.groupedOrders ?? {
      enabled: false,
      minutes: 0,
    };
  }
}

export interface IPlaceListingResponse {
  places: IPlaceResponse[];
}
