import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { PoweredByCaspeco } from 'components/custom-icons/PoweredByCaspeco';
import { useDisclosure } from '@caspeco/casper-ui-library.components.modal';
import { Drawer } from '@caspeco/casper-ui-library.components.drawer';
import { useAuthAPI } from 'store/authStore';
import { useCookieModalAPI } from 'store/cookieModalStore';
import { useCartAPI, useCartStore } from 'store/cartStore';
import { useMenuStore } from 'store/menuStore';
import { usePickSearchParams } from 'hooks/usePickSearchParams';
import { createBSTLSearchParams } from 'helpers/search-param-helper/search-param-helper';
import { logDebug } from 'helpers/log-helper/log-helper';
import { ProtectedRoutes } from 'types/routes';
import { DeliveryType, IChangeDeliveryTypeRequest } from 'types/deliveryOption';
import { LogPrefix } from 'types/logging';
import { FeedbackModal } from 'components/feedback-modal/FeedbackModal';
import ThemeModal from 'components/theme-modal/ThemeModal';
import PlaceModal from 'components/place-modal/PlaceModal';
import LanguageModal from 'components/language-modal/LanguageModal';
import { HistoryModal } from 'components/history-modal/HistoryModal';
import DeliveryOptionsModal from 'components/delivery-options-modal/DeliveryOptionsModal';
import { useBSTLUserAPI } from 'store/bstlUserStore';
import { config, EEnvironment } from '../../config/app.config';
import { SideNavigationHeader } from './subcomponents/side-navigation-header/SideNavigationHeader';
import { SideNavigationList } from './subcomponents/side-navigation-list/SideNavigationList';
import { SignOutButton } from './subcomponents/sign-out-button/SignOutButton';
import { useShowSignOut } from './hooks/useShowSignOut';

interface ISideNavigation {
  isSideNavOpen: boolean;
  onCloseSideNav: () => void;
  route: ProtectedRoutes;
}

export function SideNavigation({
  isSideNavOpen,
  onCloseSideNav,
  route,
}: ISideNavigation) {
  const [logoClickCount, setLogoClickCount] = useState(0);
  const { t } = useTranslation();
  const showSignOut = useShowSignOut();
  const [, setSearchParams] = useSearchParams();
  const { showToast } = useToast();
  const { setCartDeliveryType } = useCartAPI();
  const { cart } = useCartStore();
  const { menu } = useMenuStore();
  const { showMenuSectionList } = usePickSearchParams([
    'showMenuSectionList',
    'deliveryOptionType',
    'deliveryOptionId',
  ]);
  const { setCookieModalOpen } = useCookieModalAPI();
  const { setIsSignInModalOpen } = useAuthAPI();
  const { setIsAccountModalOpen } = useBSTLUserAPI();

  const {
    isOpen: isThemeModalOpen,
    onClose: onCloseThemeModal,
    onOpen: onOpenThemeModal,
  } = useDisclosure();

  const {
    isOpen: isHistoryModalOpen,
    onClose: onCloseHistoryModal,
    onOpen: onOpenHistoryModal,
  } = useDisclosure({
    onOpen: onCloseSideNav,
  });

  const {
    isOpen: isLanguageModalOpen,
    onClose: onCloseLanguageModal,
    onOpen: onOpenLanguageModal,
  } = useDisclosure({
    onOpen: onCloseSideNav,
  });

  const {
    isOpen: isPlaceModalOpen,
    onClose: onClosePlaceModal,
    onOpen: onOpenPlaceModal,
  } = useDisclosure({
    onOpen: onCloseSideNav,
  });

  const {
    isOpen: isDeliveryOptionsModalOpen,
    onClose: onCloseDeliveryOptionsModal,
    onOpen: onOpenDeliveryOptionsModal,
  } = useDisclosure({
    onOpen: onCloseSideNav,
  });

  const {
    isOpen: isFeedbackModalOpen,
    onClose: onCloseFeedbackModal,
    onOpen: onOpenFeedbackModal,
  } = useDisclosure({
    onOpen: onCloseSideNav,
  });

  // "Global" modal, rendered in PageWrapper, not here
  const onOpenCookieModal = () => {
    onCloseSideNav();
    setCookieModalOpen();
  };

  // "Global" modal, rendered in PageWrapper, not here
  const onOpenSignInModalModal = () => {
    onCloseSideNav();
    setIsSignInModalOpen(true, true);
  };

  const onSubmit = (
    type: DeliveryType | undefined,
    resourceId?: string,
    resourceName?: string,
  ) => {
    if (!type || !menu) return;
    const search = createBSTLSearchParams(
      type,
      resourceId,
      Boolean(showMenuSectionList),
    );
    if (cart) {
      const changeRequest: IChangeDeliveryTypeRequest = {
        resourceId,
        type,
      };

      setCartDeliveryType(cart.cartId, changeRequest)
        .then(() => {
          setSearchParams(search);
          logDebug(
            LogPrefix.Cart,
            `Changed delivery from [Type: ${cart.deliveryInfo.type}, Resource: ${cart.deliveryInfo.eatHereNodeResourceName}] to [Type: ${type}, Resource: ${resourceName}]`,
          );
        })
        .catch(() => {
          showToast({
            type: 'error',
            description: t('error_generic_reload_page'),
          });
        });
    } else {
      setSearchParams(search);
    }
    onCloseDeliveryOptionsModal();
  };

  const onOpenAccountModal = () => {
    onCloseSideNav();
    setIsAccountModalOpen(true);
  };

  const renderSideNavFooter = () => {
    if (config.environment === EEnvironment.prod) {
      return (
        <Flex alignSelf="flex-end" justify="center" w="100%">
          <PoweredByCaspeco />
        </Flex>
      );
    }

    // If NOT production environment, render logo with a debug theme button,
    // clicking it five times triggers debug feature "Change theme" in a theme Modal

    const onClickLogo = () => {
      setLogoClickCount((prev) => prev + 1);
      if (logoClickCount > 4) {
        onOpenThemeModal();
        setLogoClickCount(0);
      }
    };

    return (
      <>
        <ThemeModal isOpen={isThemeModalOpen} onClose={onCloseThemeModal} />
        <button
          id="logo-button"
          type="button"
          aria-label="Toggle theme selection"
          onClick={onClickLogo}
          tabIndex={-1}
          style={{
            width: '100%',
            alignSelf: 'flex-end',
            cursor: 'default',
            padding: `${ThemeSpaceVariable.Large} 0`,
          }}
        >
          <Flex justify="center">
            <PoweredByCaspeco />
          </Flex>
        </button>
      </>
    );
  };

  return (
    <>
      {/* #region local modals */}
      <HistoryModal isOpen={isHistoryModalOpen} onClose={onCloseHistoryModal} />
      <PlaceModal isOpen={isPlaceModalOpen} onClose={onClosePlaceModal} />
      <LanguageModal
        onClose={onCloseLanguageModal}
        isOpen={isLanguageModalOpen}
      />
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={onCloseFeedbackModal}
      />
      <DeliveryOptionsModal
        isOpen={isDeliveryOptionsModalOpen}
        onClose={onCloseDeliveryOptionsModal}
        deliveryTypes={menu?.deliveryTypesSupported ?? []}
        onSubmit={onSubmit}
      />
      {/* #region endregion modals */}

      <Drawer
        isOpen={isSideNavOpen}
        bg={ThemeColorVariable.Background}
        onClose={onCloseSideNav}
        placement="left"
        size="xs"
        autoFocus={false}
        overflowY="auto"
      >
        <SideNavigationHeader onClose={onCloseSideNav} />
        <Box
          as="aside"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          h="100%"
          pb={ThemeSpaceVariable.Medium}
          bg={ThemeColorVariable.Background}
          color={ThemeColorVariable.OnBackground}
        >
          <Box>
            <SideNavigationList
              route={route}
              onClickAccount={onOpenAccountModal}
              onClickChangeDeliveryOption={onOpenDeliveryOptionsModal}
              onClickChangeLanguage={onOpenLanguageModal}
              onClickOpeningHoursAndContant={onOpenPlaceModal}
              onClickSignIn={onOpenSignInModalModal}
              onClickCookies={onOpenCookieModal}
              onClickHistory={onOpenHistoryModal}
              onClickContactAppName={onOpenFeedbackModal}
            />
            {showSignOut && <SignOutButton />}
          </Box>
          {renderSideNavFooter()}
        </Box>
      </Drawer>
    </>
  );
}
