import { createContext, useContext, useMemo, useReducer } from 'react';
import { logDebug, logError, logInfo } from 'helpers/log-helper/log-helper';
import { OrderService } from 'services/Order.service';
import { LogPrefix } from 'types/logging';
import { Order } from 'types/order';
import { BSTLPaymentRequest, ProviderPaymentMethod } from 'types/payment';
import { getLocaleFromCurrentLang } from 'helpers/locale-helper/locale-helper';
import { IBSTLUserRequestValues } from 'types/bstlUser';
import { BSTLApiError } from 'types/api-error';
import { setSessionStorage } from 'helpers/session-storage-helper/session-storage-helper';
import { config, EEnvironment } from 'config/app.config';

interface IOrderStore {
  readonly order: undefined | Order;
  /**
   * Is currently fetching order
   */
  readonly isLoadingOrder: boolean;
  /**
   * Has finished fetch request for order,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedOrder: boolean;
}

interface IOrderAPI {
  /**
   * Fetch order for given id.
   */
  getOrder: (orderId: string) => Promise<Order>;
  /**
   * Fetch order for given id, using /public endpoint (non-protected).
   */
  getPublicOrder: (orderId: string) => Promise<Order>;
  /**
   * Create order based on cart with given id and optional contact info (for users with no accounts).
   * @param emailAddress optional for anonymous users
   * @param phoneNumber optional for anonymous users
   */
  createOrder: (
    cartId: string,
    guest?: Partial<IBSTLUserRequestValues>,
    overridePhoneNumber?: string,
  ) => Promise<Order>;
  /**
   * Delete order
   */
  deleteOrder: (orderId: string) => Promise<void>;
  /**
   * Fetch receipt for
   * @returns Receipt for order in Blob file format.
   */
  getReceipt: (orderId: string) => Promise<Blob>;
  /**
   * Will initiate a Reepay Payment session for paying order, using selected @param paymentMethod.
   * If the order has a total amount of 0 cents to pay, a redirect to order confirmation page happens.
   * @param orderId
   * @param providerPaymentMethod
   */
  initiatePayment: (
    orderId: string,
    providerPaymentMethod: ProviderPaymentMethod,
    registeredUserPurchase: boolean,
    marking?: string,
  ) => Promise<void>;
}

enum ActionType {
  SET_ORDER,
  START_LOADING_ORDER,
  STOP_LOADING_ORDER,
  RESET,
}

type Action =
  | { type: ActionType.SET_ORDER; payload: Order | undefined }
  | { type: ActionType.START_LOADING_ORDER }
  | { type: ActionType.STOP_LOADING_ORDER };

const OrderStore = createContext<IOrderStore>({} as IOrderStore);
const OrderAPI = createContext<IOrderAPI>({} as IOrderAPI);

const initialState: IOrderStore = {
  order: undefined,
  isLoadingOrder: false,
  hasLoadedOrder: false,
};

const reducer = (state: IOrderStore, action: Action): IOrderStore => {
  switch (action.type) {
    case ActionType.SET_ORDER:
      return {
        order: action.payload,
        isLoadingOrder: false,
        hasLoadedOrder: true,
      };
    case ActionType.START_LOADING_ORDER:
      return {
        ...state,
        isLoadingOrder: true,
        hasLoadedOrder: false,
      };
    case ActionType.STOP_LOADING_ORDER:
      return {
        ...state,
        isLoadingOrder: false,
        hasLoadedOrder: true,
      };
    default:
      return state;
  }
};

export function OrderProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IOrderStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IOrderAPI = useMemo(() => {
    const getOrder = async (orderId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_ORDER });
        const order = await OrderService.getOrder(orderId);
        dispatch({ type: ActionType.SET_ORDER, payload: order });
        return order;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_ORDER });
        logError(LogPrefix.Order, error, `Could not load Order: ${orderId}`);
        throw error;
      }
    };

    const getPublicOrder = async (orderId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_ORDER });
        const order = await OrderService.getPublicOrder(orderId);
        dispatch({ type: ActionType.SET_ORDER, payload: order });
        return order;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_ORDER });
        logError(LogPrefix.Order, error, `Could not load Order: ${orderId}`);
        throw error;
      }
    };

    const createOrder = async (
      cartId: string,
      guest?: Partial<IBSTLUserRequestValues>,
      overridePhoneNumber?: string,
    ) => {
      try {
        dispatch({ type: ActionType.START_LOADING_ORDER });

        let order: Order;
        if (guest) {
          order = await OrderService.createOrder(cartId, {
            emailAddress: guest.emailAddress,
            phoneNumber: guest.phoneNumber,
            locale: guest.locale || getLocaleFromCurrentLang(),
          });
        } else {
          // Is signed in and registered BSTL user, no need to provide user info when creating order
          // the bearer token used for the request will identify the registered user
          order = await OrderService.createOrder(
            cartId,
            undefined,
            overridePhoneNumber,
          );
        }

        logInfo(
          LogPrefix.Order,
          `Created Order: ${order.orderId} from CartId: ${cartId}`,
        );

        setSessionStorage('orderId', order.orderId);
        dispatch({ type: ActionType.SET_ORDER, payload: order });
        return order;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_ORDER });
        logError(
          LogPrefix.Order,
          error,
          `Could not create Order from Cart: ${cartId}`,
        );
        throw error;
      }
    };

    const deleteOrder = async (orderId: string) => {
      try {
        await OrderService.deleteOrder(orderId);
        logDebug(LogPrefix.Order, `Deleted Order ${orderId}`);
        dispatch({ type: ActionType.SET_ORDER, payload: undefined });
      } catch (error) {
        logError(LogPrefix.Order, error, `Could not delete Order ${orderId}}`);
        throw error;
      }
    };

    const initiatePayment = async (
      orderId: string,
      providerPaymentMethod: ProviderPaymentMethod,
      registeredUserPurchase: boolean,
      marking?: string,
    ) => {
      try {
        // Create payment request
        const paymentRequest = new BSTLPaymentRequest({
          orderId,
          providerPaymentMethod,
          marking,
          registeredUserPurchase,
        });

        // Initiate payment
        const paymentResponse = await OrderService.initiatePayment(
          orderId,
          paymentRequest,
        );
        logDebug(
          LogPrefix.Order,
          `Initiated payment for Order: ${orderId} using Method: ${providerPaymentMethod.paymentMethod}`,
        );

        // If the order is already considered paid at this point, it had a cost of 0 (free)
        // and we redirect to order confirmation route, skipping Reepay Checkout
        if (paymentResponse.paymentStatus === 'PAID') {
          window.location.assign(paymentRequest.acceptUrl);
          logInfo(
            LogPrefix.Order,
            `No payment required. Redirecting to ${paymentRequest.acceptUrl}`,
          );
          return; // Finish here, don't init any Reepay Checkout session
        }

        if (config.environment == EEnvironment.local) {
          location.href = paymentResponse.redirectUrl;
        } else {
          // Init Reepay Checkout session
          const paymentSessionId = paymentResponse.providerOrderId;

          // Note, we do not have a type definition for the Reepay SDK,
          // so we disable the eslint rule for this call below.
          // For docs, see https://optimize-docs.billwerk.com/docs/window-checkout

          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          await new Reepay.WindowCheckout(paymentSessionId);
        }
      } catch (error) {
        logError(
          LogPrefix.Order,
          error,
          `Failed to initiate payment for Order ${orderId}`,
        );
        throw error;
      }
    };

    const getReceipt = async (orderId: string) => {
      try {
        const blob = await OrderService.getReceipt(orderId);
        return blob;
      } catch (error) {
        const errorTitle = `Could not get receipt`;

        if (error instanceof BSTLApiError && error.statusCode === 404) {
          logError(
            LogPrefix.Order,
            // Construct new error with message for log purposes,
            // as this is a pdf-endpoint (does not contain error json-response)
            new Error(`Found no receipt for Order: ${orderId}`),
            errorTitle,
          );
        } else {
          logError(
            LogPrefix.Order,
            new Error(`Failed to fetch receipt for Order: ${orderId}`),
            errorTitle,
          );
        }

        throw error; // Rethrow original error to be caught by caller
      }
    };

    return {
      createOrder,
      deleteOrder,
      getOrder,
      getPublicOrder,
      initiatePayment,
      getReceipt,
    };
  }, []);

  return (
    <OrderAPI.Provider value={api}>
      <OrderStore.Provider value={state}>{children}</OrderStore.Provider>
    </OrderAPI.Provider>
  );
}

export const useOrderStore = (): IOrderStore => useContext(OrderStore);
export const useOrderAPI = (): IOrderAPI => useContext(OrderAPI);
