import { createContext, useContext, useMemo, useReducer } from 'react';
import {
  addLogAttribute,
  logDebug,
  logError,
  removeLogAttribute,
} from 'helpers/log-helper/log-helper';
import { UserService } from 'services/User.service';
import { BSTLApiError } from 'types/api-error';
import { getLocaleFromCurrentLang } from 'helpers/locale-helper/locale-helper';
import { deleteStoredUserInfo } from 'helpers/auth-helper/auth-helper';
import { BSTLUser, IBSTLUserRequestValues } from 'types/bstlUser';
import { LogPrefix } from 'types/logging';

export interface IBSTLUserStore {
  readonly bstlUser: BSTLUser | undefined;
  /**
   * Is currently fetching user
   */
  readonly isLoadingUser: boolean;
  /**
   * Has finished fetch request for user,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedUser: boolean;
  /**
   * When true will display a complete registration of user modal in ui.
   */
  readonly isRegisterUserModalOpen: boolean;

  /**
   * When true will display account modal in ui.
   */
  readonly isAccountModalOpen: boolean;
}

interface IBSTLUserAPI {
  /**
   * Fetch user for given id
   */
  getUser: (userId: string) => Promise<BSTLUser | undefined>;
  /**
   * Create a new user
   */
  createUser: (
    name?: string,
    emailAdress?: string,
    phoneNumber?: string,
  ) => Promise<BSTLUser>;
  /**
   * Update user
   */
  updateUser: (user: IBSTLUserRequestValues) => Promise<void>;
  /**
   * Removes any current stored user from UserStore.
   */
  resetUser: () => void;
  /**
   * Delete user forever.
   */
  deleteUser: (userId: string) => Promise<void>;
  /**
   * Open or close register user modal.
   */
  setIsRegisterUserModalOpen: (isOpen: boolean) => void;

  /**
   * Change the user’s language if the current language differs from it.
   */
  syncUserLocaleWithCurrent: (user: BSTLUser) => Promise<void>;

  /**
   *
   * Open or close account modal.
   */
  setIsAccountModalOpen: (isOpen: boolean) => void;
}

const initialState: IBSTLUserStore = {
  bstlUser: undefined,
  isLoadingUser: false,
  hasLoadedUser: false,
  isRegisterUserModalOpen: false,
  isAccountModalOpen: false,
};

enum ActionType {
  SET_USER,
  START_LOADING_USER,
  STOP_LOADING_USER,
  SET_IS_REGISTER_USER_MODAL_OPEN,
  SET_IS_ACCOUNT_MODAL_OPEN,
}
type Action =
  | { type: ActionType.SET_USER; payload: BSTLUser | undefined }
  | { type: ActionType.START_LOADING_USER }
  | { type: ActionType.STOP_LOADING_USER }
  | { type: ActionType.SET_IS_REGISTER_USER_MODAL_OPEN; payload: boolean }
  | { type: ActionType.SET_IS_ACCOUNT_MODAL_OPEN; payload: boolean };

const BSTLUserAPI = createContext<IBSTLUserAPI>({} as IBSTLUserAPI);
const BSTLUserStore = createContext<IBSTLUserStore>({} as IBSTLUserStore);

const reducer = (state: IBSTLUserStore, action: Action): IBSTLUserStore => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        bstlUser: action.payload,
        isLoadingUser: false,
        hasLoadedUser: true,
      };
    case ActionType.START_LOADING_USER:
      return {
        ...state,
        isLoadingUser: true,
        hasLoadedUser: false,
      };
    case ActionType.STOP_LOADING_USER:
      return {
        ...state,
        isLoadingUser: false,
        hasLoadedUser: true,
      };
    case ActionType.SET_IS_REGISTER_USER_MODAL_OPEN:
      return {
        ...state,
        isRegisterUserModalOpen: action.payload,
      };
    case ActionType.SET_IS_ACCOUNT_MODAL_OPEN:
      return {
        ...state,
        isAccountModalOpen: action.payload,
      };
    default:
      return state;
  }
};

export function BSTLUserProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IBSTLUserStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IBSTLUserAPI = useMemo(() => {
    const createUser = async (
      name?: string,
      emailAdress?: string,
      phoneNumber?: string,
    ) => {
      try {
        dispatch({ type: ActionType.START_LOADING_USER });
        // Create a new BSTL-guest user
        const user = await UserService.createUser({
          name: name ?? '',
          emailAddress: emailAdress ?? '',
          phoneNumber: phoneNumber ?? '',
          locale: getLocaleFromCurrentLang(),
        });
        dispatch({ type: ActionType.SET_USER, payload: user });
        addLogAttribute('guestId', user.guestId);
        return user;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_USER });
        logError(LogPrefix.User, error, 'Could not create new user');
        throw error;
      }
    };

    const getUser = async (userId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_USER });
        const user = await UserService.getUser(userId);
        dispatch({ type: ActionType.SET_USER, payload: user });
        addLogAttribute('guestId', user.guestId);
        return user;
      } catch (error) {
        if (error instanceof BSTLApiError && error.statusCode === 404) {
          dispatch({ type: ActionType.STOP_LOADING_USER });
          return undefined;
        }
        dispatch({ type: ActionType.STOP_LOADING_USER });
        throw error;
      }
    };

    const deleteUser = async (userId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_USER });
        logDebug(LogPrefix.User, `Deleting User`);
        await UserService.deleteUser(userId);
        dispatch({ type: ActionType.SET_USER, payload: undefined });
        deleteStoredUserInfo();
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_USER });
        logError(LogPrefix.User, error, `Could not delete User`);
        throw error;
      }
    };

    const updateUser = async (user: IBSTLUserRequestValues) => {
      try {
        dispatch({ type: ActionType.START_LOADING_USER });
        const updatedUser = await UserService.updateUser(user);
        dispatch({ type: ActionType.SET_USER, payload: updatedUser });
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_USER });
        logError(LogPrefix.User, error, 'Could not update User');
        throw error;
      }
    };

    const resetUser = () => {
      removeLogAttribute('guestId');
      dispatch({ type: ActionType.SET_USER, payload: undefined });
    };

    const setIsRegisterUserModalOpen = (isOpen: boolean) => {
      dispatch({
        type: ActionType.SET_IS_REGISTER_USER_MODAL_OPEN,
        payload: isOpen,
      });
    };

    const syncUserLocaleWithCurrent = async (user: BSTLUser) => {
      const currentLocale = getLocaleFromCurrentLang();
      if (user.locale != currentLocale) {
        await updateUser({
          ...user,
          locale: currentLocale,
        });
      }
    };

    const setIsAccountModalOpen = (isOpen: boolean) => {
      dispatch({
        type: ActionType.SET_IS_ACCOUNT_MODAL_OPEN,
        payload: isOpen,
      });
    };

    return {
      createUser,
      getUser,
      updateUser,
      resetUser,
      setIsRegisterUserModalOpen,
      deleteUser,
      syncUserLocaleWithCurrent,
      setIsAccountModalOpen,
    };
  }, []);

  return (
    <BSTLUserAPI.Provider value={api}>
      <BSTLUserStore.Provider value={state}>{children}</BSTLUserStore.Provider>
    </BSTLUserAPI.Provider>
  );
}

export const useBSTLUserAPI = (): IBSTLUserAPI => useContext(BSTLUserAPI);
export const useBSTLUserStore = (): IBSTLUserStore => useContext(BSTLUserStore);
