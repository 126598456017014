import { IOrderResponse, Order } from 'types/order';
import {
  IInitiatePaymentResponse,
  BSTLPaymentRequest,
  InitatetedPayment,
} from 'types/payment';
import { IBSTLUserRequestValues } from 'types/bstlUser';
import { ApiService } from './Api.service';

// API Layer
export class OrderAPIService {
  static async createOrder(
    cartId: string,
    user?: Partial<IBSTLUserRequestValues>,
    overridePhoneNumber?: string,
  ): Promise<IOrderResponse> {
    return ApiService.fetch(`orders`, {
      method: 'POST',
      body: JSON.stringify({ cartId, user, overridePhoneNumber }),
    });
  }

  static async deleteOrder(orderId: string): Promise<void> {
    return ApiService.fetch(`orders/${orderId}`, {
      method: 'DELETE',
    });
  }

  static async getPublicOrder(orderId: string): Promise<IOrderResponse> {
    return ApiService.fetch(`orders/${orderId}/public`);
  }

  static async getOrder(orderId: string): Promise<IOrderResponse> {
    return ApiService.fetch(`orders/${orderId}`);
  }

  static async initiatePayment(
    orderId: string,
    paymentRequest: BSTLPaymentRequest,
  ): Promise<IInitiatePaymentResponse> {
    return ApiService.fetch(`orders/${orderId}/initiate-payment`, {
      method: 'POST',
      body: JSON.stringify(paymentRequest),
    });
  }

  static async getReceipt(orderId: string): Promise<Blob> {
    return ApiService.fetch(
      `orders/${orderId}/receipt`,
      {
        method: 'GET',
        headers: {
          accept: 'application/pdf',
        },
      },
      true,
    );
  }
}

// Dto layer
export class OrderService {
  static async createOrder(
    cartId: string,
    user?: Partial<IBSTLUserRequestValues>,
    overridePhoneNumber?: string,
  ): Promise<Order> {
    return OrderAPIService.createOrder(cartId, user, overridePhoneNumber).then(
      (data: IOrderResponse) => new Order(data),
    );
  }

  static async deleteOrder(orderId: string): Promise<void> {
    return OrderAPIService.deleteOrder(orderId);
  }

  static async getPublicOrder(orderId: string): Promise<Order> {
    return OrderAPIService.getPublicOrder(orderId).then(
      (data: IOrderResponse) => new Order(data),
    );
  }

  static async getOrder(orderId: string): Promise<Order> {
    return OrderAPIService.getOrder(orderId).then(
      (data: IOrderResponse) => new Order(data),
    );
  }

  static async initiatePayment(
    orderId: string,
    paymentRequest: BSTLPaymentRequest,
  ): Promise<InitatetedPayment> {
    return OrderAPIService.initiatePayment(orderId, paymentRequest).then(
      (data: IInitiatePaymentResponse) => new InitatetedPayment(data),
    );
  }

  static async getReceipt(orderId: string): Promise<Blob> {
    return OrderAPIService.getReceipt(orderId).then(
      (data: Blob) => new Blob([data], { type: 'application/pdf' }),
    );
  }
}
