import { ImageMap, ImageMapValues } from 'types/imageMap';
import { IPriceIncludingVatValues, PriceIncludingVat } from './price';

export type AddableToCartFailReasons = 'CONFLICTING_HOURS';

type MenuArticleStatus = 'OUT_OF_STOCK' | 'ENABLED';

export interface ISubArticleResponse {
  id?: string;
  name?: string;
  description?: string;
  inHousePriceIncludingVat?: IPriceIncludingVatValues;
  takeAwayPriceIncludingVat?: IPriceIncludingVatValues;
  sortOrder?: number;
  imageMap?: ImageMapValues;
  status?: string;
}

export class SubArticle {
  id: string;
  name: string;
  description: string;
  inHousePriceIncludingVat: PriceIncludingVat;
  takeAwayPriceIncludingVat: PriceIncludingVat;
  sortOrder: number;
  imageMap: ImageMap;
  status: MenuArticleStatus;

  constructor(values: ISubArticleResponse) {
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.description = values?.description ?? '';
    this.imageMap = new ImageMap(values?.imageMap);
    this.sortOrder = values?.sortOrder ?? -1;
    this.inHousePriceIncludingVat = new PriceIncludingVat(
      values?.inHousePriceIncludingVat,
    );
    this.takeAwayPriceIncludingVat = new PriceIncludingVat(
      values?.takeAwayPriceIncludingVat,
    );
    this.status = (values?.status ?? 'ENABLED') as MenuArticleStatus;
  }
}

interface IAddonResponse {
  id: string;
  name: string;
  description: string;
  sortOrder: number;
  items: ISubArticleResponse[];
}

export class ArticleAddon {
  id: string;
  name: string;
  description: string;
  sortOrder: number;
  items: SubArticle[];

  constructor(values: IAddonResponse) {
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.description = values?.description ?? '';
    this.sortOrder = values?.sortOrder ?? -1;
    this.items =
      values?.items
        ?.map((i) => new SubArticle(i))
        .sort((a, b) => a.sortOrder - b.sortOrder) ?? [];
  }
}

export interface IArticleResponse {
  id: string;
  name: string;
  description: string;
  imageMap: ImageMapValues;
  sortOrder: number;
  inHousePriceIncludingVat: IPriceIncludingVatValues;
  takeAwayPriceIncludingVat: IPriceIncludingVatValues;
  extras: IAddonResponse[];
  options: IAddonResponse[];
  discountable: boolean;
  hasUpsellGroups: boolean;
  addableToCart: boolean;
  addableToCartFailReasons: string[];
}

export class Article {
  id: string;
  name: string;
  description: string;
  imageMap: ImageMap;
  sortOrder: number;
  inHousePriceIncludingVat: PriceIncludingVat;
  takeAwayPriceIncludingVat: PriceIncludingVat;
  extras: ArticleAddon[];
  options: ArticleAddon[];
  discountable: boolean;
  hasUpsellGroups: boolean;
  addableToCart: boolean;
  addableToCartFailReasons: AddableToCartFailReasons[];

  constructor(values: IArticleResponse) {
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.description = values?.description ?? '';
    this.imageMap = new ImageMap(values?.imageMap);
    this.sortOrder = values?.sortOrder ?? -1;
    this.inHousePriceIncludingVat = new PriceIncludingVat(
      values?.inHousePriceIncludingVat,
    );
    this.takeAwayPriceIncludingVat = new PriceIncludingVat(
      values?.takeAwayPriceIncludingVat,
    );
    this.extras =
      values?.extras
        ?.map((e) => new ArticleAddon(e))
        .sort((a, b) => a.sortOrder - b.sortOrder) ?? [];
    this.options =
      values?.options
        ?.map((e) => new ArticleAddon(e))
        .sort((a, b) => a.sortOrder - b.sortOrder) ?? [];
    this.discountable = values?.discountable ?? false;
    this.hasUpsellGroups = values?.hasUpsellGroups ?? false;
    this.addableToCart = values?.addableToCart ?? true;
    this.addableToCartFailReasons =
      (values?.addableToCartFailReasons as AddableToCartFailReasons[]) ?? [];
  }
}

export interface IUpsellArticleValues extends ISubArticleResponse {
  requiresArticleDetailsPrompt: boolean;
  addableToCart: boolean;
  addableToCartFailReasons: AddableToCartFailReasons[];
}

export class UpsellArticle extends SubArticle {
  requiresArticleDetailsPrompt: boolean;
  addableToCart: boolean;
  addableToCartFailReasons: AddableToCartFailReasons[];

  constructor(values: IUpsellArticleValues) {
    super(values);
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.description = values?.description ?? '';
    this.imageMap = new ImageMap(values?.imageMap);
    this.sortOrder = values?.sortOrder ?? -1;
    this.inHousePriceIncludingVat = new PriceIncludingVat(
      values?.inHousePriceIncludingVat,
    );
    this.takeAwayPriceIncludingVat = new PriceIncludingVat(
      values?.takeAwayPriceIncludingVat,
    );
    this.requiresArticleDetailsPrompt = values?.requiresArticleDetailsPrompt;
    this.addableToCart = values?.addableToCart ?? true;
    this.addableToCartFailReasons = values?.addableToCartFailReasons ?? [];
  }
}
